<template>
  <div class="flex-grow">
    <div class="alert bg-yellow-200 text-yellow-900 p-2 text-xl text-center" role="alert">
      <button type="button" class="close float-right" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      All scoring has been locked and disabled for this selection.&nbsp;
      <span v-if="manage">
        Enable scoring by toggling the 'Disable Scoring' switch,
        located on the <a href="#/manage/settings" class="cursor-pointer text-blue-600">Settings page.</a>
      </span>
      <span v-else>Please contact the tournament director to enable scoring access.</span>
      &nbsp;This scoring page must be refreshed after scoring has been enabled.
    </div>
  </div>
</template>

<script>

export default {
  name: "ScoringDisabled",
  props: ['manage'],
}
</script>

<style scoped>

</style>
