<template>
  <div>
    <setup-codes v-if="showCodes" :tournament="tournament"/>
    <div v-else-if="showPortal" class="portal">
      <ScoringPortal/>
    </div>
    <div v-else class="flex flex-col h-full">
      <app-banner :tournament="tournament"/>
      <scoring-disabled v-if="tournament.lock_scoring" :manage="authManage"/>
      <div v-else class="w-full px-3 bg-white flex-grow">
        <scoring-unauthorized
          v-if="disabled || (!verified && !inc_competitor && tournament.club_id != null)"
          :tournament="tournament"
          :code="code"
          :disabled="disabled"/>
        <div v-if="readyToScore">
          <div v-if="showLock">
            <lock-device :user="user"/>
            <setup-finder/>
          </div>
          <div v-else>
            <div class="flex justify-between flex-wrap p-2">
              <i @click="lockClicked" class="order-1 text-gray-700 text-5xl fas fa-lock-alt"></i>
              <restrict-scoring/>
              <sync-button :key="`${tournament.id}_sync_button_key`"/>
            </div>
            <scoring-disabled v-if="lockedForSelection" :manage="authManage"/>
            <div v-else-if="!round.hidden || (round.hidden && authManage)" class="flex flex-col lg:w-3/4 lg:mx-auto ">
              <end-display/>
              <div v-for="(bale, baleNumber) in validBaleCompetitors"
                   class="md:mx-auto md:w-3/4 mb-10" :ref="'bale_' + baleNumber">
                <a @click.prevent="addBale(baleNumber)" href=""
                   v-if="Object.values(bale).length && !competitor"
                   class="text-blue-600 cursor-pointer flex justify-end text-xl border-b">
                  {{ this.$trans.choice('search.bale', 1) }} {{ baleNumber }}
                </a>
                <score-panel :key="`${baleNumber}_score_panel`" :bale="bale" :bale-number="baleNumber"/>
              </div>
              <div v-if="!filteredCount()" class="text-center">
                <p>No Assigned Competitors</p>
              </div>
            </div>
            <div v-else class="flex">
              <span>Scoring for this round is not publicly available.</span>
              <tool-tip :size="'w-full'" class="text-lg flex flex-col justify-center ml-2 mb-4">
                <template v-slot:message>
                  This round has been hidden by the tournament director. To make it publicly available, admin can toggle
                  the switch located at Tournament -> Manage -> Edit -> Setup.
                </template>
              </tool-tip>
            </div>
          </div>
        </div>
        <div v-else class="text-center py-32">
          <h1 class="mt-4">Loading Scores</h1>
          <span class="m-5"><i class="fas fa-spinner fa-spin fa-5x"></i></span>
        </div>
      </div>
      <scoring-device/>
    </div>
  </div>

</template>

<script>
import LockDevice from "./lock/LockDevice.vue";
import SetupFinder from "../SetupFinder.vue";
import ScoringUnauthorized from "./ScoringUnauthorized.vue";
import RestrictScoring from "./restrict/RestrictScoring.vue";
import SyncButton from "./SyncButton.vue";
import mixin from "../../mixins/scoring_mixins";
import EndDisplay from "./EndDisplay.vue";
import ScorePanel from "./panel/ScorePanel.vue";
import ScoringDisabled from "./ScoringDisabled.vue";
import {mapWritableState} from "pinia";
import {useDisplayStore} from "../../stores/DisplayStore";
import ToolTip from "../partials/ToolTip.vue";
import Dropdown from "../partials/Dropdown.vue";
import AppBanner from "./AppBanner.vue";
import {BsAlert} from "../../mixins/swal_mixins";
import ScoringDevice from "./ScoringDevice.vue";
import SetupCodes from "../tournaments/manage/score_codes/SetupCodes.vue";
import ScoringPortal from "../tournaments/manage/score_codes/ScoringPortal.vue";

export default {
  name: "ScoringComponent",
  mixins: [mixin],
  components: {
    ScoringPortal,
    SetupCodes,
    ScoringDevice,
    AppBanner,
    ToolTip,
    Dropdown,
    EndDisplay,
    LockDevice,
    RestrictScoring,
    ScorePanel,
    ScoringDisabled,
    ScoringUnauthorized,
    SetupFinder,
    SyncButton,
  },
  props: ['tournamentIn', 'user', 'inc_competitor'],
  data() {
    return {
      store: useDisplayStore(),
      disabled: false,
      code: '',
    }
  },
  mounted() {
    let vm = this;
    window.addEventListener('beforeunload', function (event) {
      if (vm.notAccepted.length) {
        event.preventDefault();
        event.returnValue = BsAlert.fire({
          titleText: 'Some scores have not been accepted!',
          html: 'Please either Accept or Deny any scores before leaving this page',
          showCancelButton: false,
        }).then(() => {
          return false;
        });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.notAccepted.length) {
      BsAlert.fire({
        titleText: 'Some scores have not been accepted!',
        html: 'You must either Accept or Deny any scores before leaving this page',
        showCancelButton: false,
      }).then(() => {
        next(false)
      })
    } else {
      if (this.locked && to.path !== '/complete') {
        if (this.tournament.club_id && this.tournament.lock_code) {
          BsAlert.fire({
            titleText: 'This device is locked for scoring!',
            html: 'You must unlock it before leaving this page.',
            icon: 'info',
            input: 'text',
            confirmButtonText: 'Unlock',
            inputValidator: (value) => {
              if (value !== this.tournament.lock_code) {
                return 'Incorrect Code'
              }
              this.unlockDevice();
              return next();
            }
          }).then(({dismiss}) => {
            if (dismiss) {
              return next(false)
            }
          })
        } else {
          this.unlockDevice();
          return next();
        }
      } else {
        return next();
      }
    }
  },
  computed: {
    ...mapWritableState(useDisplayStore, [
      'round',
      'lineTime',
      'location',
      'shownBales',
      'hideShown',
      'restrictedBales',
      'restrictedLocations',
      'restrictedTimes',
      'restrictedRounds',
      'restrictedCompetitors',
      'showLock',
      'locked',
      'verified',
      'device',
      'tournaments',
      'clubs',
      'competitor',
      'ladder',
      'scoreOnline'
    ]),
    showCodes() {
      return !this.store.usedCode && this.authManage && !this.scoreOnline;
    },
    showPortal() {
      return !this.store.usedCode && !this.scoreOnline;
    },
    async usedCode() {
      let code = await this.$localForage.getItem(`${this.tournament.id}_code`);
      return code != null;
    },
    authManage() {
      /** @namespace this.user.auth_manage **/
      return this.user && this.user.auth_manage;
    },
    
    lockedForSelection() {
      let list = this.tournament.lockedList;
      if (!list.locked) return false;
      if (list.tournament) return true;
      if (list.rounds[this.round.id].locked) return true;
      return list.rounds[this.round.id].times[this.lineTime.id].locked;
    },
    
    readyToScore() {
      if (!this.tournamentLoaded) return false;
      if (!this.round) return false;
      if (!this.lineTime) return false;
      if (!this.location) return false;
      return this.scoreList;
    },
    tournamentLoaded() {
      return this.tournament && this.tournament.hasOwnProperty('unassignedCompetitors')
    },
  },
  methods: {
    validBale(bale) {
      debugger;
      return Object.keys(bale).length;
    },
    addBale(bale) {
      let exists = this.shownBales.indexOf(bale);
      if (exists === -1) {
        let copy = [...this.shownBales];
        copy.push(bale);
        this.shownBales = copy;
        this.hideShown = false;
        this.$nextTick(() => {
          let scrollTo = 'bale_' + bale;
          let container = this.$refs[scrollTo][0];
          container.scrollIntoView();
          window.scrollBy(0, -65);
        });
      }
    },
    addRestricted(item, list, mutation) {
      let exists = list.indexOf(item);
      if (exists === -1) {
        list.push(item);
        this[`restricted${mutation}`] = list;
      }
    },
    
    filteredCount() {
      return Object.values(this.filteredCompetitors).reduce(function (a, c) {
        return a + Object.values(c).length;
      }, 0);
    },
    
    getRounds() {
      let rounds = this.restrictedRounds;
      if (rounds.length) {
        let filtered = this.tournamentIn.rounds.filter(function (r) {
          return rounds.includes(r.id);
        });
        if (filtered.length) return filtered;
        return rounds;
      }
      return this.tournamentIn.rounds;
    },
    goToCompetitor(competitor) {
      this.location = this.tournament.locations.find(f => f.name === competitor.location);
      this.shownBales.push(competitor.bale);
    },
    handleIncomingCompetitor() {
      this.clearRestricted();
      if (!this.inc_competitor) return;
      this.unlockDevice();
      this.restrictedCompetitors = [this.inc_competitor.id];
      let firstLoop = true;
      for (let assignment of this.inc_competitor.assignments) {
        this.addRestricted(assignment.bale, this.restrictedBales, 'Bales');
        let location = this.locationFromAssignment(assignment);
        if (location) this.addRestricted(location, this.restrictedLocations, 'Locations');
        let time = this.timeFromAssignment(assignment);
        if (time) this.addRestricted(time, this.restrictedTimes, 'Times');
        this.addRestricted(assignment.round_id, this.restrictedRounds, 'Rounds');
        if (firstLoop) {
          this.round = this.tournament.rounds[0];
          let lineTime = this.round.line_times.find(f => f.id === parseInt(assignment.line_time));
          let location = this.round.locations.find(f => f.name === assignment.location);
          this.lineTime = this.store.$patch({
            lineTime: lineTime,
            location: location,
          })
          firstLoop = false;
        }
      }
    },
    locationFromAssignment(assignment) {
      return this.tournament.locations.find(f => f.name === assignment.location);
    },
    lockClicked() {
      if (!this.tournament.club_id || !(this.tournament.lock_code && this.tournament.lock_code.length)) {
        return this.showLock = true;
      }
      BsAlert.fire({
        title: 'Enter Lock Code',
        input: 'text',
        inputValidator: (value) => {
          if (value.toLowerCase() !== this.tournament.lock_code.toLowerCase()) {
            return 'Incorrect Code'
          }
          this.showLock = true;
        }
      })
    },
    async setExisting() {
      let key = this.tournament.id + '_locked_';
      for (const name of ['Rounds', 'Times', 'Locations', 'Bales', 'Competitors']) {
        let value = await this.$localForage.getItem(`${key}${name.toLowerCase()}`);
        if (value && value.length) {
          let bad = false;
          for (let item of value) {
            //This does not work with ===
            if (item == undefined) {
              bad = true;
              break;
            }
          }
          this.setRestricted(name, bad ? [] : value);
        }
      }
    },
    
    clearRestricted() {
      this.store.$patch({
        restrictedBales: [],
        restrictedCompetitors: [],
        restrictedLocations: [],
        restrictedTimes: [],
        restrictedRounds: [],
      })
    },
    
    setRestricted(name, list) {
      this[`restricted${name}`] = list && list.length ? list : [];
    },
    
    async setTournamentLocked() {
      let locked = await this.$localForage.getItem(this.tournament.id + '_lock');
      if (locked) this.locked = true;
    },
    setVerified() {
      if (this.authManage || !this.tournamentIn.club_id) {
        this.verified = true;
      } else {
        let tId = this.tournamentIn.id;
        let cId = this.tournamentIn.club_id;
        let devices = this.tournamentIn.club_devices;
        let exists = this.$store.getters['device/verify'](tId, cId, devices);
        if (!exists) {
          this.$store.dispatch('device/updateAuthorizations').then(() => {
            exists = this.$store.getters['device/verify'](tId, cId, devices);
            this.verified = exists;
          });
        } else {
          this.verified = exists;
        }
      }
    },
    async updateAuthorizations() {
      if (this.device == null) return;
      await this.$axios.get('/device', {
        params: {
          'device_id': this.device.device_id,
        }
      }).then(async ({data}) => {
        this.device = data.device;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    timeFromAssignment(assignment) {
      return this.tournament.line_times.find(f => f.id === parseInt(assignment.line_time));
    },
    async setDisabledCode() {
      let code = await this.$localForage.getItem(`${this.tournament.id}_code`);
      if (code != null) {
        if (code.disabled) {
          this.code = code.code;
          return this.disabled = true;
        }
      }
      this.disabled = false
    },
  },
  
  created() {
    this.store.$patch({
      user: this.user,
      end: 1,
      ladder: null,
    })
    let vm = this;
    this.handleIncomingCompetitor();
    this.setTournamentLocked();
    this.setDisabledCode();
    this.setExisting().then(function (_) {
      let rounds = vm.getRounds();
      let set = false;
      rounds.some(function (r) {
        let rTime = vm.restrictedTimes.length ? null : r.line_times[0];
        let rLocation = vm.restrictedLocations.length ? null : r.locations[0];
        vm.restrictedTimes.forEach(function (t) {
          let exists = r.line_times.findIndex(f => f.id === t.id);
          if (exists !== -1) {
            rTime = r.line_times[exists];
          }
        });
        vm.restrictedLocations.forEach(function (l) {
          let exists = r.locations.findIndex(f => f.id === l.id);
          if (exists !== -1) {
            rLocation = r.locations[exists];
          }
        });
        if (rTime && rLocation) {
          vm.store.$patch({
            round: r,
            lineTime: rTime,
            location: rLocation,
          })
          set = true;
          return true;
        }
      });
      if (!set) {
        vm.store.$patch({
          round: rounds[0],
          lineTime: rounds[0].line_times[0],
          location: rounds[0].locations[0],
        })
      }
    });
    let bales = this.restrictedBales;
    if (bales && bales.length) {
      this.hideShown = false;
      this.shownBales = [...bales];
    }
    this.setVerified();
    
  },
}
</script>

<style scoped>
.portal {
  width: 100%;
  min-width: 300px;
  max-width: 600px;
}
</style>
